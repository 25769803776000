<template>
  <footer class="text-sm text-center py-8">
    Created with
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5 text-red-custom inline-block"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
        clip-rule="evenodd"
      />
    </svg>
    by
    <a href="https://instagram.com/ikhfan_yusuf" class="text-blue-500"
      >Ikhfan Yusuf</a
    >
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
